<script>
    import Football from "@/services/Football";
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";

    export default {
        components:{
            vueDropzone: vue2Dropzone,
        },
        props: {
            sportTeamData: {
                type: [Array, Object],
                default: () => ({})
            },
        },
        data() {
            return {
                csrf_token: localStorage.getItem("csrf_token"),
                showModal: false,
                tryingToEdit: false,
                status_new: 0,
                show_image: false,
                file_e: '',
                home_shirt: '',
                away_shirt: '',
                photoDeleted: false,
                photoDeletedAway: false,
                photoDeletedHome: false,
                dropzoneOptions_primary: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles: 'image/*',
                    maxFiles: 3,
                },
                dropzoneOptions_home: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles: 'image/*',
                    maxFiles: 3,
                },
                dropzoneOptions_away: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 200,
                    thumbnailMethod: 'contain',
                    maxFilesize: 5,
                    acceptedFiles: 'image/*',
                    maxFiles: 3,
                },
            };
        },
        methods: {
            refreshData() {
                this.$emit('onRefresh');
            },
            async updateSportTeam() {
                this.tryingToEdit = true;
                const id = this.sportTeamData.team_id;
                const data = {
                    team_name_overwrite: this.sportTeamData.team_name,
                    team_code_overwrite: this.sportTeamData.team_name_short,
                    team_code: this.sportTeamData.team_code_new,
                    team_type: this.sportTeamData.team_type,
                    home_shirt: this.home_shirt,
                    away_shirt: this.away_shirt,
                    team_logo: this.file_e ,
                };
                try {
                    await Football.updateSportTeam(id, data,{
                    }).then((response) => {
                        this.successmsg("Change successfully saved");
                        this.refreshData()
                        this.closeModal();
                    }).catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg("Failed to save changes")
                    }).finally(() => {
                        this.tryingToEdit = false;
                    })
                }
                catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.failedmsg(this.error)
                }
            },
            closeModal() {
                this.showModal = false;
                this.photoDeleted = false;
                this.photoDeletedAway = false;
                this.photoDeletedHome = false;

                this.file_e = '';
                this.home_shirt =  '';
                this.away_shirt =  '';
                this.$emit("onCancel");
                this.$bvModal.hide("edit_sport_team");
                this.refreshData();
            },
            handleModalClose() {
                this.refreshData();
                this.photoDeleted = false;
                this.photoDeletedAway = false;
                this.photoDeletedHome = false;
                this.file_e = '';
                this.home_shirt =  '';
                this.away_shirt =  '';
            },
            deleteImage() {
                this.photoDeleted = true;
                this.file_e = '1';
                this.sportTeamData.team_logo = '';
            },
            deleteImageHome() {
                this.photoDeletedHome = true;
                this.home_shirt = '1';
                this.sportTeamData.home_shirt = '';
            },
            deleteImageAway() {
                this.photoDeletedAway = true;
                this.away_shirt = '1';
                this.sportTeamData.away_shirt = '';
            },
            selectFile(files) {
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_primary.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one primary image');

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_primary.removeFile(value);
                        });
                    }
                    this.file_e = queuedFiles[0];
                    this.photoDeleted = false;
                }, 250);
            },
            selectFileAway(files) {
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_primary_away.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one primary image');

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_primary_away.removeFile(value);
                        });
                    }
                    this.away_shirt = queuedFiles[0];
                    this.photoDeletedAway = false;
                }, 250);
            } ,
            selectFileHome(files) {
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone_primary_home.getQueuedFiles();

                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one primary image');

                        files.forEach((value) => {
                            this.$refs.myVueDropzone_primary_home.removeFile(value);
                        });
                    }
                    this.home_shirt = queuedFiles[0];
                    this.photoDeletedHome = false;
                }, 250);
            }
        }
    };
</script>

<template>
    <b-modal v-model="showModal" id="edit_sport_team" title="Edit Sport Team" title-class="font-18" centered size="lg"  @hide="handleModalClose">
        <form @submit.prevent="updateSportTeam">
            <div class="form-group" style="font-weight: 500; margin-bottom: 10px">
                <div style=" margin-bottom: 3px;">Team ID : {{sportTeamData.team_id}} </div>
                <div  style=" margin-bottom: 3px;"> Team Name : {{sportTeamData.team_name_new}} </div>
                <div  style=" margin-bottom: 3px;">Team Code : {{sportTeamData.team_code_new}} </div>
                <div>Provider : {{sportTeamData.provider}} </div>
            </div>
            <div class="form-group">
                <label for="team_name_overwrite">Team Name Overwrite:</label>
                <input type="text" id="team_id" class="form-control" style="display: none;" v-model="sportTeamData.team_id" />
                <input type="text" id="team_name_overwrite" class="form-control" v-model="sportTeamData.team_name" />
            </div>
            <div class="form-group">
                <label for="team_name_short">Team Code Overwrite:</label>
                <input type="text" id="team_name_short" class="form-control" v-model="sportTeamData.team_name_short" />
            </div>
            <div class="form-group">
                <label for="team_type">Team Type:</label>
                <input type="text" id="team_type" class="form-control" v-model="sportTeamData.team_type" />
            </div>
            <div class="form-group " >
                <label for="home_shirt">Home Shirt:</label>
                <template v-if="!photoDeletedHome && sportTeamData.home_shirt">
                    <b-form-group label-for="image" class="mb-3 text-center image-area">
                        <img :src="sportTeamData.home_shirt" alt="" width="200" class="img-thumbnail" />
                    </b-form-group>
                    <div class="text-center mt-3">
                        <a class="btn text-danger" v-b-tooltip.hover title="Delete Image" @click="deleteImageHome">
                            <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                    </div>
                </template>
                <template v-else>
                    <vue-dropzone
                            id="dropzone_primary_home_shirt"
                            ref="myVueDropzone_primary_home"
                            :use-custom-slot="true"
                            :options="dropzoneOptions_primary"
                            @vdropzone-files-added="selectFileHome"
                            no-progress-bar
                    >
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop file here or click to upload.</h4>
                        </div>
                    </vue-dropzone>
                </template>
            </div>
            <div class="form-group" >
                <label for="team_logo">Away Shirt:</label>
                <template v-if="!photoDeletedAway && sportTeamData.away_shirt">
                    <b-form-group label-for="image" class="mb-3 text-center image-area">
                        <img :src="sportTeamData.away_shirt" alt="" width="200" class="img-thumbnail" />
                    </b-form-group>
                    <div class="text-center mt-3">
                        <a class="btn text-danger" v-b-tooltip.hover title="Delete Image" @click="deleteImageAway">
                            <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                    </div>
                </template>
                <template v-else>
                    <vue-dropzone
                            id="dropzone_primary_away_shirt"
                            ref="myVueDropzone_primary_away"
                            :use-custom-slot="true"
                            :options="dropzoneOptions_primary"
                            @vdropzone-files-added="selectFileAway"
                            no-progress-bar
                    >
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop file here or click to upload.</h4>
                        </div>
                    </vue-dropzone>
                </template>
            </div>

            <div class="form-group">
                <label for="team_logo">Team Logo:</label>
                <template v-if="!photoDeleted && sportTeamData.team_logo">
                    <b-form-group label-for="image" class="mb-3 text-center image-area">
                        <img :src="sportTeamData.team_logo" alt="" width="200" class="img-thumbnail" />
                    </b-form-group>
                    <div class="text-center mt-3">
                        <a class="btn text-danger" v-b-tooltip.hover title="Delete Image" @click="deleteImage">
                            <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                    </div>
                </template>
                <template v-else>
                    <vue-dropzone
                            id="dropzone_primary"
                            ref="myVueDropzone_primary"
                            :use-custom-slot="true"
                            :options="dropzoneOptions_primary"
                            @vdropzone-files-added="selectFile"
                            no-progress-bar
                    >
                        <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop file here or click to upload.</h4>
                        </div>
                    </vue-dropzone>
                </template>
            </div>
        </form>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="updateSportTeam" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Save
            </b-button>
        </template>
    </b-modal>
</template>

